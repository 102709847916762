import { useCmsData } from 'utils/cms';
import WidgetsParser from '../../widgets';

const Content = () => {
  const {
    acf: { widgets },
  } = useCmsData();

  return <WidgetsParser widgets={widgets} />;
};
export default Content;
