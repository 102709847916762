/* eslint-disable camelcase */
import clx from 'classnames';
import dynamic from 'next/dynamic';
import { ElementType } from 'react';

import { DtoPage } from 'types/cms';
import { Mapped } from 'types/utils';

type Props = {
  widgets: DtoPage['acf']['widgets'];
  dataId?: string | null;
  className?: string;
};

const WIDGET_MAPPER: Mapped<string, ElementType> = {
  featured_content: dynamic(() => import('./nav/FeaturedContent')),
  accordion: dynamic(() => import('./edito/Accordion')),
  articles_list: dynamic(() => import('./data/ArticlesList')),
  events_list: dynamic(() => import('./data/EventsList')),
  shop_events: dynamic(() => import('./data/ShopEvents')),
  accordion_extract: dynamic(() => import('./data/AccordionExtract')),
  content_block: dynamic(() => import('./edito/ContentBlock')),
  content_image_block: dynamic(() => import('./edito/ContentImageBlock')),
  images_carrousel: dynamic(() => import('./edito/ImagesCarrousel')),
  testimonials_carrousel: dynamic(() => import('./edito/TestimonialsCarrousel')),
  my_events: dynamic(() => import('./data/MyEvents')),
  my_game_history: dynamic(() => import('./data/MyGameHistory')),
  action_tiles: dynamic(() => import('./nav/ActionTiles')),
  my_wantlist: dynamic(() => import('./data/MyWantList')),
  my_offers: dynamic(() => import('./data/MyOffers')),
  shop_photos: dynamic(() => import('./data/ShopPhotos')),
  social_media: dynamic(() => import('./edito/SocialMedia')),
  timeline: dynamic(() => import('./edito/Timeline')),
  new_title: dynamic(() => import('./edito/NewTitle')),
  thumbnails_with_legend: dynamic(() => import('./edito/ThumbnailsWidget')),
  nav_container: dynamic(() => import('./nav/Container')),
  hero: dynamic(() => import('./edito/Hero')),
  metadata: dynamic(() => import('./edito/MetaDataWidget')),
  big_divider_title: dynamic(() => import('./edito/BigDividerTitle')),
  new_big_divider_title: dynamic(() => import('./edito/NewBigDividerTitle')),
  videos_block: dynamic(() => import('./edito/VideosBlock')),
  card_extract: dynamic(() => import('./data/CardExtract')),
  wide_slideshow: dynamic(() => import('./edito/WideSlideshow')),
  cover_homepage: dynamic(() => import('./edito/CoverHomepage')),
  articles_emphasis: dynamic(() => import('./data/ArticlesEmphasis')),
  deck_extract: dynamic(() => import('./data/DeckExtract')),
  value_button_group: dynamic(() => import('./edito/ValueButtonGroup')),
  push_mobile_app: dynamic(() => import('./edito/PushMobileApp')),
};

const getRestrictedAccessClassName = (restrictedDeviceAccess: Array<'desktop' | 'mobile'>) => {
  if (restrictedDeviceAccess.includes('mobile') && !restrictedDeviceAccess.includes('desktop')) {
    return 'md:hidden';
  }
  if (restrictedDeviceAccess.includes('desktop') && !restrictedDeviceAccess.includes('mobile')) {
    return 'hidden md:block';
  }

  return '';
};

const WidgetsParser = ({ widgets, dataId }: Props) => (
  <>
    {widgets.map((widget) => {
      const Component = WIDGET_MAPPER[widget.acf_fc_layout];
      if (widget.acf_fc_layout === 'nav_container') {
        const { widgets: nestedWidgets, ...componentProps } = widget;

        return (
          <div
            key={widget.id}
            className={getRestrictedAccessClassName(widget.restricted_device_access_for)}
            data-attr-widget-name={widget.acf_fc_layout}
            id={widget.id}
          >
            <Component {...componentProps}>
              <WidgetsParser dataId={dataId} widgets={nestedWidgets} />
            </Component>
          </div>
        );
      }

      return (
        Component && (
          <div
            key={widget.id}
            className={getRestrictedAccessClassName(widget.restricted_device_access_for)}
            data-attr-widget-name={widget.acf_fc_layout}
            id={widget.id}
          >
            <Component
              key={widget.id}
              className={getRestrictedAccessClassName(widget.restricted_device_access_for)}
              dataId={dataId}
              {...widget}
            />
          </div>
        )
      );
    })}
  </>
);

const WidgetsWrapper = ({ widgets, dataId, className }: Props) => (
  <div className={clx('flex flex-col gap-y-8', className)}>
    <WidgetsParser dataId={dataId} widgets={widgets} />
  </div>
);
export default WidgetsWrapper;
